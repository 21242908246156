@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.skeletonTemplate {
    max-width: rem(348);
	height: auto;

	.skeletonDetails p{
		background-color: $primary-fog-900;
		width: 100%;
		height: rem(20);
		border-radius: 20px;
	}

	p:last-child {
		width: 70%;
	}
}

.card {
	display: flex;
	flex-direction: column;
	gap: rem(8);
	background-color: $primary-fog-50;
	height: 100%;
	width: 100%;

	& .inner {
		background-color: white;
		margin: 0;
		padding: 1rem;
		flex: 1;
		display: flex;
		flex-direction: column;

		& .pricingContainer,
		& .login {
			margin-top: auto;
		}

		& .login {
			@include underlinedLink;
		}
	}
}